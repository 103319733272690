<template>
    <section>
        <modal ref="modalVerComision" titulo="Pago Comisión" icon="aprobar" tamano="modal-lg" no-aceptar no-cancelar>
            <div class="row mx-0 justify-center">
                <div class="col-10 border bg-light-f5 px-3 py-3 br-10">
                    <div class="row mx-0">
                        <div class="col-6 my-2">
                            <i class="icon-receipt f-18" />
                            <span class="text-general">
                                {{ infoPago.teorico.pedidos }} Pedidos
                            </span>
                        </div>
                        <div class="col-6 my-2">
                            <i class="icon-calendar-empty f-18" />
                            <span class="text-general">
                                Entre: {{ `${formatearFecha(infoPago.fecha_inicio)} - ${formatearFecha(infoPago.fecha_fin)}` }}
                            </span>
                        </div>
                        <div class="col-6 my-2">
                            <i class="icon-currency-usd-circle f-18 text-general2" />
                            <span class="text-general">Ventas: {{ separadorNumero(infoPago.teorico.ventas) }}</span>
                        </div>
                        <div v-if="infoPago.verBotones" class="col-6 my-2">
                            <i class="icon-cash-multiple f-18 text-general2" />
                            <span class="text-general">Comisión Sugerida: {{ separadorNumero(infoPago.teorico.comisiones) }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="row pl-3 my-2">
                        <img :src="infoPago.creador.foto" class="obj-cover rounded-circle" width="76" height="76" />
                        <div class="col">
                            <p class="text-general f-17 my-2 f-600">
                                {{ infoPago.creador.nombre }}
                            </p>
                            <p class="text-general f-15 my-2 f-500">
                                Responsable del registro
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 my-2">
                        <div class="col my-2 px-0">
                            <p class="text-general">
                                <span class="f-600">Comisión Pagada: </span> {{ separadorNumero(infoPago.valor) }}
                            </p>
                        </div>
                        <div class="col my-2 px-0">
                            <p class="text-general">
                                <span class="f-600"> Fecha: </span>  {{ infoPago.fecha_pago }}
                            </p>
                        </div>
                    </div>
                    <p class="mt-3 text-general f-600">
                        Observaciones
                    </p>
                    <p class="text-general f-15">
                        {{ infoPago.observacion }}
                    </p>
                </div>
                <div v-if="infoPago.anulacion != null" class="col-10 mt-4">
                    <div class="row mx-0">
                        <div class="col d-flex">
                            <div class="col-auto px-0">
                                <i class="icon-cash-remove f-25 text-general2" />
                            </div>
                            <div class="col">
                                <p class="text-general f-18 mt-1">
                                    Registro anulado
                                </p>
                                <p>
                                    <span class="text-general f-18 f-600">Fecha: </span>
                                    {{ formatearFecha(infoPago.updated_at) }}
                                </p>
                            </div>
                        </div>
                        <div class="col d-flex">
                            <img :src="infoPago.anulador.foto" class="obj-cover rounded-circle" width="76" height="76" />
                            <div class="col">
                                <p class="text-general f-18">
                                    {{ infoPago.anulador.nombre }}
                                </p>
                                <p class="f-14 text-general f-400">
                                    Responsable del registro
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="mt-3 text-general f-600">
                        Observaciónes
                    </p>
                    <p class="text-general f-15">
                        {{ infoPago.anulacion }}
                    </p>
                </div>
                <div v-else class="col-10">
                    <div v-if="infoPago.verBotones" class="row mx-0 justify-content-end my-3">
                        <div v-if="$can('boton_tesoreria_comisiones_anular_pago')" class="bg-light-f5 text-general2 br-10 px-3 shadow cr-pointer" @click="anularPago">
                            Anular Pago
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <!-- Partials -->
        <modal-anular-pago ref="modalAnularPago" @listar="listar" />
    </section>
</template>

<script>
export default {
    components: {
        modalAnularPago: () => import('./modalAnularPago')
    },
    data(){
        return {
            infoPago:{
                id: null,
                anulacion: null,
                creador: {
                    nombre: '',
                    foto: '',
                },
                anulador: {
                    nombre: '',
                    foto: '',
                },
                teorico:{
                    pedidos: 0,
                    ventas: 0,
                    comisiones: 0,
                },
                created_at: null,
                created_by: null,
                fecha_fin: null,
                fecha_inicio: null,
                fecha_pago: null,
                id_tienda: null,
                idm_moneda: null,
                observacion: null,
                pago: null,
                updated_at: null,
                updated_by: null,
                valor: null,
                verBotones: null,
            }
        }
    },
    methods: {
        toggle(row){
            this.infoPago = { ...row }
            this.$refs.modalVerComision.toggle();
        },
        anularPago(){
            this.$refs.modalVerComision.toggle();
            this.$refs.modalAnularPago.toggle(this.infoPago.id);
        },
        listar(){
            this.$emit('listar')
        }
    }
}
</script>
